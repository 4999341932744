.img_container {
    height: 20%;
}

.img_container img {
    height: 100%;
}

.login-title {
    background: #10243A;
    padding: 1.5em;
    color: white !important;
    border-radius: 15px;
    font-size: 1.5rem;
}