@import "./icons.scss";
@import "./bootstrap.scss";
@import "./app.scss";
@import "./_new_varaibles.scss";
body {
    * {
        outline: none;
    }
}

#sidebar-menu {
    ul {
        li {
            a {
                i {
                    min-width: 2rem;
                }
            }
        }
    }
}

.fa,
.fas {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

[class*=" ti-"],
[class^="ti-"] {
    font-family: themify;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.noti-icon .badge {
    left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
    float: right;
}

.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
}

.dz-message {
    text-align: center;
    margin: 2em 0;
}

// kanban board
.react-kanban-column {
    height: 100%;
    min-height: 28px;
    display: inline-block;
    padding: 15px;
    border-radius: 2px;
    background-color: rgb(238, 238, 238);
    margin: 5px;
    vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
    background-color: $card-bg !important;
    margin: 0 20px 0 0 !important;
}

.task-box {
    border: 1px solid $border-color;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.ReactModal__Overlay {
    z-index: 1001 !important;
}

.chat-conversation .right .conversation-list {
    margin-right: 15px;
}

.external-event {
    &:hover {
        cursor: pointer;
    }
}

a:hover {
    cursor: pointer;
}

.rating-container {
    background-color: transparent !important;
}

.input-group-append {
    z-index: 0;
}

.input-color {
    color: $input-color !important;
}

.sketch-picker {
    position: absolute;
    z-index: 1;
}

.rangeslider__fill {
    background-color: $primary !important;
}

.flatpickr-months .flatpickr-month {
    background: $primary !important;
}

.flatpickr-weekdays {
    background: $primary !important;
}

span.flatpickr-weekday {
    background: $primary !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    background: $primary !important;
}

span.flatpickr-weekday {
    color: $white !important;
}

.flatpickr-day.selected {
    background: $primary !important;
}

//File-manager
.file-manager {
    .table td {
        padding: 0.35rem;
    }
}

//Crypto enteries
.bs-select select {
    width: auto !important;
}

//Authenication carousel
.slider_css {
    .slide {
        background: none !important;
    }
    .carousel-status,
    .control-next,
    .control-prev {
        display: none !important;
    }
}

.carousel {
    .control-dots {
        margin: -5px 0px;
    }
    .dot {
        background: #556ee6 !important;
        width: 9px !important;
        height: 9px !important;
    }
}

// calendar modal
.close {
    background: transparent;
    border: 0;
    font-size: 10px;
    padding: 1.35rem 1.25rem;
    background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.5;
    width: 1em;
    height: 1em;
    z-index: 2;
    span {
        display: none;
    }
}

.wizard .actions {
    position: relative !important;
    display: block !important;
    text-align: right !important;
    width: 100% !important;
}

.wizard .actions>ul {
    display: block !important;
    text-align: right !important;
    padding-left: 0 !important;
}

.wizard .actions>ul>li {
    display: inline-block !important;
    margin: 0 0.5em !important;
}

//Dropdown
.dropup .dropdown-menu {
    top: auto !important;
    bottom: 100% !important;
    transform: translate3d(5px, 5px, 0px) !important;
}

//form editor
.rdw-editor-toolbar,
.rdw-editor-main {
    border: 1px solid #ccc !important;
}

//Calendar
.app-calendar .btn {
    text-transform: capitalize;
}

.dropzone:focus {
    outline: none !important;
}

//dropdown
.dropdown-menu.dropdown-menu-right {
    right: 0 !important;
    left: auto !important;
}

.card-columns {
    column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 24px;
    column-gap: 24px;
    orphans: 1;
    widows: 1;
}

.ion-ios-checkmark-circle-outline {
    font-size: 3.5rem;
    line-height: 56px;
}

label {
    margin-bottom: 0.5rem;
}

.DraftEditor-editorContainer {
    margin-left: 14px !important;
}

.wizard .steps .nav-link {
    background-color: $background;
    color: #626ed4;
}

.wizard .steps .nav-link .number {
    border-color: $background;
}

.login-side-container {
    background: $background;
    height: 98vh;
}

.login-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.property-content-container {
    width: 90%;
    margin: 0 auto
}

.login-header,
.login-btn {
    background: $background;
}

.login-btn {
    color: white;
}

.login-btn:hover,
.login-btn:focus {
    color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    transition: 0.3s;
    background: $background;
    transform: translateY(-0.25em);
}

.remember {
    color: $background;
}

.remember:hover {
    color: $background;
    transform: translateY(-0.25em);
}

.login-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
}

.login-img-container img {
    width: 50%;
    margin: 0 auto;
}

// .account-pages .logo-admin {
//     border-bottom: 4px solid $background;
// }
.welcome {
    border-radius: $border-radius;
    background: $background;
    color: $white;
}

.bd-rds {
    border-radius: $border-radius;
}

.TenantHeader {
    background-color: $background;
    padding: 1em;
    color: white;
}

.bd-rds-header {
    border-top-left-radius: $header-Border-raddius-top-left;
    border-top-right-radius: $header-Border-raddius-top-right;
}

.newTenantSubmitBtn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome {
    border-radius: $border-radius;
    background: $background;
    color: $white
}

.bd-rds {
    border-radius: $border-radius
}

.wizard .steps .nav-link {
    background: $background;
    color: $white;
    border-radius: $border-radius
}

.wizard>.steps .current a,
.wizard>.steps .current a:active,
.wizard>.steps .current a:hover {
    background: $secondary_bg;
    border-radius: $border-radius
}

.wizard .steps .nav-link .number {
    border-color: $white;
}

//!! Table Header Color
.sorting_desc {
    // background: $background !important;
    // color: white !important;
    text-align: center !important
}

//Active pagination color
.active .page-link {
    background: $background !important
}

.share-Container {
    border: 1px dashed;
    min-height: 60vh;
    border-radius: $border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}

.share-icon {
    color: #cfcfcf
}

.container-content {
    background: white;
    border-radius: $border-radius
}

.module-content-container {
    height: 90vh;
    justify-content: center;
    display: flex
}

.module-container {
    width: 70%;
    margin: 0 auto
}

ul li {
    list-style-type: none;
    padding: .5em 0
}

.continue-register {
    justify-content: center;
    align-items: center;
    display: flex
}

.module-container-page {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto
}

.bg-table-header,
.btn-view-detail {
    // background: $background;
    color: $white
}

.service-card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.login-container {
    width: 40%;
    margin: 0 auto
}

@media(max-width: 768px) {
    .login-container {
        width: 90%;
        margin: 0 auto
    }
}
 
.bg-img {
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    border-radius: 15px;
    position: relative
}

.img-opacity {
    background: black;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0.6;
    border-radius: 15px
}

.cards-img {
    width: 90%;
    margin: 0 auto;
}

.cards-img img {
    width: 100%;
}

.login-card-hover:hover {
    background: $report-success;
    transition: all 0.5s ease;
}

.login-card-hover:hover h4 {
    color: $white !important
}

.logo-sm {
    width: 100% !important;
}

.logo-sm img {
    width: 45px !important;
    margin-left: -15px !important;
}

.logo-lg {
    width: 100% !important;
}

.logo-lg img {
    width: 200px!important;
    margin-left: -105px !important;
}

.residential-clr {
    background-color: $report-success;
}

.commercial-clr {
    background-color: $commercial-clr;
}

.phone_number {
    border: none !important;
    border: 0px solid white !important
}

::-webkit-input-placeholder {
    color: #dbdbdb !important;
}

:-ms-input-placeholder {
    color: #dbdbdb !important;
}

::placeholder {
    color: #dbdbdb !important;
}

.form-control {
    background: #f3f3f3a2 !important;
    border: none;
    padding: 0.8em;
    // color: #0A8A6B !important;
    color: #8392AB !important;
}

.property-img {
    width: 100%
}

.property-img img {
    width: 100%;
    border-radius: 15px
}

// ======================================
//===== CHADRACK CSS====//
//===== CUSTOM CSS====//
//========================================
.Container_h {
    height: 98vh;
    margin: 0;
    height: 100vh;
    overflow: hidden;
}

// Login, register bg-image
.bg-image {
    background-image: url('../images/Auth-img/login.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    background: linear-gradient(135deg, $gradient_left_color 0%, $gradient_right_color 100%);
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
    border-radius: $border-radius;
    border: none
}

.profile__btn {
    background: none;
}

.Link_color {
    color: $custom-text-color !important;
}

.apply__btn {
    background: $client__color !important;
}

.btn__table {
    font-size: 10px;
}

.btn-status {
    border-radius: 100px !important;
    border: none;
    font-size: 10px;
    -webkit-box-shadow: -1px 0px 24px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 0px 24px -12px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 0px 24px -12px rgba(0, 0, 0, 0.75);
}

.btn-suspend {
    border-radius: 15px !important;
    border: none;
    -webkit-box-shadow: -1px 0px 24px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 0px 24px -12px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 0px 24px -12px rgba(0, 0, 0, 0.75);
}

.color__warning {
    background: #e6d708;
    color: #000000;
}

.color__green {
    background: #0ace35;
    color: #ffffff;
}

.color__blue {
    background: $client__color;
    color: #ffffff;
}

.color__red {
    background: #ff6060;
    color: #ffffff;
}

.color__black {
    background: #000000;
    color: #ffffff;
}

.color__verify {
    background: #1c9bf0;
    color: #ffffff;
}

.color__download {
    background: #cc9e0a;
    color: #ffffff;
}

::-webkit-input-placeholder {
    color: $custom-text-color;
}

:-ms-input-placeholder {
    color: $custom-text-color;
}

::placeholder {
    color: $custom-text-color;
}

.bg-card {
    background: $background
}

.report-danger {
    background: $report-danger
}

.report-warning {
    background: $report-warning
}

.report-primary {
    background: $report-primary
}

.report-success {
    background: $report-success
}

.card-border-radius {
    border-radius: $card-border-radius
}

.icon-color {
    color: $report-primary
}

.show-password {
    cursor: pointer;
    display: flex;
    justify-content: end;
    align-items: center;
}

.custom-color {
    color: $custom__text_color;
}

.btn-custom-color {
    color: $client__color;
}

.user__name {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user__name .btn {
    background: $client__color !important;
}

.image__container {
    width: 50% !important;
    display: flex;
    gap: 1em;
}

.img__container img {
    width: 100%;
    height: 100%;
    border: 1px solid #cacaca !important;
    border-radius: 15px;
}

.image__container img {
    width: 100%;
    height: 100%;
    border: 1px solid #cacaca !important;
    border-radius: 15px;
    padding: 1em;
}

.avatar-lg {
    border-radius: 15px;
}

.loan__form {
    padding: 2px;
}

.title {
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 1em;
}

.payslip__img__container {
    border-radius: 15px !important;
}

.payslip__title {
    text-align: center !important;
}

.modal__header {
    background-color: $client__color;
    color: white;
}

.custom-tooltip {
    --bs-tooltip-bg: var(--bs-primary);
}



// !!!! NEW CHANGE
.fixed_height {
    min-height: 100vh;
    overflow: hidden !important;
}

.container__zero__lenght {
    text-align: center;
    margin-top: 50px;
}

.message__display {
    font-size: 24px;
    font-weight: bold;
    color: $custom__text_color;
}

.message__display__small {
    font-size: 16px;
    color: #999;
    margin-top: 20px;
}

.user__name {
    color: $client__color !important;
}

.signature-pad {
    background-color: #f0f0f0;
    border-radius: 15px;
    height: 200px;
    margin: 1em 0;
    width: 100%;
}

.section__header {
    border-bottom: 2px solid #dee2e6; /* Bootstrap's light gray border color */
    padding-bottom: 0.5rem; /* Space between text and border */
    margin-bottom: 1rem; /* Space below the header */
    font-size: 1.25rem; /* Adjust the font size as needed */
    color: #495057; /* A darker color for the header */
}


.custom-btn {
    width: 25% !important; /* Width for mobile screens */
}

.custom-size-checkbox input[type="checkbox"] {
    transform: scale(1.8); /* This enlarges the checkbox */
    margin-right: 15px; /* Adjusts spacing if needed */
}

.custom-size-checkbox  {
    background: $white !important;
}


@media (min-width: 767px) {
    .input-card {
        width: 60%;
    }
    .h-100-mobile {
        height: 100% !important;
        margin-bottom: 1em !important;
    }
    .desktop__only{
        margin-bottom: 1em !important;
    }
    
    .submitMandateBtn{
    width: 10%;   
    }
}

@media (max-width: 768px) {
    .bg-image {
        display: none;
    }
    .custom-btn {
        width: 100% !important; /* default to 100% width */
    }
    .mb__mobile {
        margin-bottom: .5em; /* Adjust the value as needed */
        margin-top: 1.5em; /* Adjust the value as needed */
      }
}


//* this is for the Toastify
@media screen and (max-width: 600px) {
    .Toastify__toast-container,
    .Toastify__toast {
        font-size: 16px;
        width: 100%;
        padding: 0 10px;
        margin-top: 0.5em;
    }
}

